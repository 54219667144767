import React, { useContext, useEffect } from "react";
import AvatarContext from "./AvatarContext";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import FilterRight from "./FilterRight";
import {
  firstTimeQuestions,
  secondTimeQuestions,
  testingQuestions,
  lynparzaQuestions,
  diseaseStateQuestions,
} from "./questions";

const RightQuestionBox = ({ selectCategoryType, questions }) => {
  const { setQuestion, setRequestLoader, requestLoader } =
    useContext(AvatarContext);

  const urlParams = new URLSearchParams(window.location.search);
  let firstTime = urlParams.get("first-time");

  // Attempt to parse the URL parameter if it's not null
  if (firstTime !== null) {
    firstTime = JSON.parse(firstTime);
  } else {
    const firstTimeLocalStorage = localStorage.getItem("firstTime");
    if (firstTimeLocalStorage !== null) {
      firstTime = JSON.parse(firstTimeLocalStorage);
    }
  }
  let firstTimeQN = [
    "What are the primary factors that increase a patient’s risk of ovarian cancer?",
    "What diagnostics are needed to identify patients who are candidates for Lynparza?",
    "Can Lynparza be used by pregnant or lactating women?",
    "What were the efficacy results in SOLO-1?",
    "What resources are available to help get patients access to Lynparza?",
  ];
  let secondTimeQN = [
    "What diagnostics are needed to identify patients who are candidates for Lynparza?",
    "How is Lynparza dosed and administered?",
    " Can you provide NDC or ICD-10 coding information or resources?",
    "Can you provide information on specialty pharmacy or specialty distributor information?",
    "Can you provide information on the Patient Savings Program?",
  ];

  const [defaultQuestions, setDefaultQuestions] = React.useState(
    questions?.firstTimeQuestions?.rightSide
      ? questions?.firstTimeQuestions?.rightSide
      : questions?.secondTimeQuestions?.rightSide,
  );

  useEffect(() => {
    if (firstTime) {
      setDefaultQuestions(questions.firstTimeQuestions?.rightSide);
    } else {
      setDefaultQuestions(questions.secondTimeQuestions?.rightSide);
    }
    Object.keys(questions).forEach((key) => {
      if (selectCategoryType === key) {
        setDefaultQuestions(questions[key].rightSide);
        return;
      }
    });
  }, [selectCategoryType, questions]);

  return (
    <div className="w-full h-full flex-col flex items-center justify-center gap-4 p-4">
      {!requestLoader && (
        <>
          {/* <FilterRight
            selectCategoryType={selectCategoryType}
            setSelectCategoryType={setSelectCategoryType}
          /> */}
          <div
            className="bg-gray-100 p-4 w-[95%]"
            data-intro="Click any question below to start a conversation!"
            data-position="top"
            data-step="1"
            style={{ border: "1.5px solid #e2e8f0", borderRadius: ".8rem" }}
          >
            {defaultQuestions?.map((item, index) => (
              <div
                key={index}
                className="flex gap-2 bg-white rounded-lg p-4 my-5 cursor-pointer"
                style={{ border: "1.5px solid #e2e8f0", borderRadius: ".8rem" }}
                onClick={(e) => {
                  setQuestion(item);
                  setRequestLoader(true);
                }}
              >
                <AiOutlineQuestionCircle className="h-5 w-5 text-gray-600" />
                <div className="text-sm flex-1">{item}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default RightQuestionBox;
