import React, { useContext, useRef, useEffect, useState } from "react";
import { Button } from "antd";
import AvatarContext from "./AvatarContext";
import { DownloadOutlined } from "@ant-design/icons";

const Answer = () => {
  const divRef = useRef(null);
  const { response, descriptionTitle, brand } = useContext(AvatarContext);
  const [scrollHeight, setScrollHeight] = useState(0);

  let fullText = response?.long_answer;

  useEffect(() => {
    if (divRef.current) {
      const height = divRef.current.scrollHeight;
      setScrollHeight(height);
    }
  }, [fullText]);

  function getPI(brand) {
    if (brand === "lynparza") {
      return "https://den8dhaj6zs0e.cloudfront.net/50fd68b9-106b-4550-b5d0-12b045f8b184/00997c3f-5912-486f-a7db-930b4639cd51/00997c3f-5912-486f-a7db-930b4639cd51_viewable_rendition__v.pdf";
    } else if (brand === "airsupra") {
      return "https://den8dhaj6zs0e.cloudfront.net/50fd68b9-106b-4550-b5d0-12b045f8b184/fe598cda-d255-4446-998e-617607f61552/fe598cda-d255-4446-998e-617607f61552_viewable_rendition__v.pdf";
    }
  }
  function learnMore(brand) {
    if (brand === "lynparza") {
      return "https://www.lynparzahcp.com/ovarian-cancer/dosing/combination-therapy.html";
    } else if (brand === "airsupra") {
      return "https://www.accessdata.fda.gov/drugsatfda_docs/label/2023/214070s000lbl.pdf";
    }
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column" }}
      className="md:mt-[1rem] mt-[4rem]"
    >
      <h1 className="text-2xl font-semibold text-gray-800 pb-4 text-center w-[90%] md:mx-0 mx-auto">
        {descriptionTitle}
      </h1>
      <div
        // className="animate-fade-in-1s"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f9fafb",
          padding: "1rem",
          border: "1.5px solid #e2e8f0",
          borderRadius: ".8rem",
          height: "fit-content",
          marginTop: descriptionTitle ? "" : "2rem",
        }}
      >
        <h2
          key={response?.short_answer}
          className="text-gray-500 italic text-center px-4 py-2 mx-auto animate-fade-in-1s"
          data-intro="Look for the brief reply from avatar in this space"
        >
          "{response?.short_answer}"
        </h2>
        <div
          style={{ display: "flex", minHeight: "18rem" }}
          className="flex-wrap justify-evenly"
        >
          <img
            key={response.image[0]}
            data-intro="Look for the image/graph in this space"
            style={{
              borderRadius: "10px",
              objectFit: "contain",
              height: "100%",
            }}
            className={`${response.image[0] ? "md:w-[40%]" : "hidden"}`}
            src={response.image[0]}
            alt=""
          />

          <div
            ref={divRef}
            key={fullText}
            data-intro="Find the in-depth explanation within this section."
            className={` 
           ${scrollHeight > 500 ? "overflow-y-scroll" : "overflow-auto"}
            md:w-[55%] w-full my-5 text-sm text-gray-600 animate-fade-in-1s`}
            style={{ maxHeight: "29rem" }}
            dangerouslySetInnerHTML={{ __html: fullText }}
          ></div>
        </div>
        <div style={{ display: "flex", alignSelf: "flex-end" }}>
          <a
            href={response?.image[0] ? response.image[0] : getPI(brand)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              data-intro="Download the extra details as a PDF."
              style={{ marginTop: "1rem", marginRight: "1rem" }}
              shape="round"
              icon={<DownloadOutlined />}
            >
              Download
            </Button>
          </a>

          <a href={learnMore(brand)} target="_blank" rel="noopener noreferrer">
            <Button
              data-intro="For more insights, click here."
              data-position="left"
              shape="round"
              style={{
                marginTop: "1rem",
                background: "#305095",
                color: "white",
              }}
            >
              Learn More
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Answer;
