export const lynparzaQuestions = {
  firstTimeQuestions: {
    leftSide: [
      "What are the primary signs and symptoms of ovarian cancer?",
      "What are the indications for Lynparza?",
      "How is Lynparza dosed and administered?",
      "Can dosing be modified if the patient has an adverse reaction?",
      "What were the most common adverse reactions  in Lynparza clinical trials?",
    ],
    rightSide: [
      "What are the primary factors that increase a patient’s risk of ovarian cancer?",
      "What diagnostics are needed to identify patients who are candidates for Lynparza?",
      "Can Lynparza be used by pregnant or lactating women?",
      "What were the efficacy results in SOLO-1?",
      "What resources are available to help get patients access to Lynparza?",
    ],
  },
  secondTimeQuestions: {
    leftSide: [
      "What is the efficacy of Lynparza in ovarian cancer?",
      "What is PAOLA-1?",
      "Can Lynparza be used by pregnant or lactating women?",
      "What is SOLO-1?",
      "What were the efficacy results in SOLO-1?",
    ],
    rightSide: [
      "What diagnostics are needed to identify patients who are candidates for Lynparza?",
      "How is Lynparza dosed and administered?",
      "Can you provide NDC or ICD-10 coding information or resources?",
      "Can you provide information on specialty pharmacy or specialty distributor information?",
      "What resources are available to help get patients access to Lynparza?",
    ],
  },
  "Disease State": {
    leftSide: [
      "Which of the following symptoms is most commonly presented by patients who are later diagnosed with ovarian cancer: intense pelvic pain, abdominal discomfort or pain, or gastrointestinal symptoms?",
      "Does hypocalcemia occur frequently in ovarian cancer patients?",
      "Should an initial evaluation for suspected ovarian cancer include all of the following: abdominal or pelvic ultrasound, chest radiography, abdominal fluorodeoxyglucose-PET/CT, and chest CT?",
      "What are the primary factors that increase a patient’s risk of ovarian cancer?",
      "Can you provide NDC or ICD-10 coding information or resources?",
    ],
    rightSide: [
      "Should all patients receive chemotherapy after cytoreductive surgery or are there some patient types which should be excluded?",
      "What are the primary signs and symptoms of ovarian cancer?",
      "What are the stages of ovarian cancer ovarian cancer?",
      "Are lactate dehydrogenase and serum cancer antigen 125 laboratory tests both typically performed in conjunction with imaging for diagnosing ovarian cancer?",
      "After reaching a certain age, shouldn’t we be screening all women in the general population for ovarian cancer?",
    ],
  },
  Testing: {
    leftSide: [
      "What is HRD?",
      "What biomarkers can be used to determine if a patient is HRD positive?",
      "What percentage of ovarian cancer patients are HRD positive?",
    ],
    rightSide: [
      "What do guidelines say about BRCA and HRD testing to identify HRD positive patients?",
      "What diagnostics are needed to identify patients who are candidates for Lynparza?",
    ],
  },
  LYNPARZA: {
    leftSide: [
      "What resources are available to help get patients access to Lynparza?",
      "Can Lynparza be used by pregnant or lactating women?",
      "Does Lynparza have any drug interactions? What should it NOT be prescribed with?",
      "What about use of Lynparza in men of reproductive potential?",
    ],
    rightSide: [
      "Can Lynparza be used to treat pediatric patients?",
      "Can Lynparza be used in patients with renal or hepatic impairment?",
      "What is the Mechanism of Action of Lynparza?",
      "What is the efficacy of Lynparza in ovarian cancer?",
    ],
  },
};

export const airsupraQuestions = {
  firstTimeQuestions: {
    leftSide: [
      "What is the efficacy of AirSupra in treating or preventing bronchoconstriction and preventing asthma exacerbations?",
      "What do guidelines say about treating both symptoms and inflammation with a rescue medication?",
      "What is the Mechanism of Action of AirSupra?",
      "How is AirSupra dosed?",
      "What are the AirSupra dosage forms and strengths?",
    ],
    rightSide: [
      "What are the indications for AirSupra?",
      "What are the common side effects for AirSupra?",
      "What are the Warnings and Precautions for AirSupra?",
      "Can you provide information on the Patient Savings Program?",
      "Do the GINA guidelines support the use of AirSupra?",
    ],
  },
  secondTimeQuestions: {
    leftSide: [
      "What is the Mechanism of Action of AirSupra?",
      "How is AirSupra dosed?",
      "What are the AirSupra dosage forms and strengths?",
      "What is the efficacy of AirSupra in treating or preventing bronchoconstriction and preventing asthma exacerbations?",
      "What do guidelines say about treating both symptoms and inflammation with a rescue medication?",
    ],
    rightSide: [
      "What are the Warnings and Precautions for AirSupra?",
      "Can you provide information on the Patient Savings Program?",
      "Do the GINA guidelines support the use of AirSupra?",
      "What are the indications for AirSupra?",
      "What are the common side effects for AirSupra?",
    ],
  },
  Airsupra: {
    leftSide: [
      "What are the indications for AirSupra?",
      "What are the common side effects for AirSupra?",
      "What are the Warnings and Precautions for AirSupra?",
      "Can you provide information on the Patient Savings Program?",
      "Do the GINA guidelines support the use of AirSupra?",
    ],
    rightSide: [
      "What is the Mechanism of Action of AirSupra?",
      "How is AirSupra dosed?",
      "What are the AirSupra dosage forms and strengths?",
      "What is the efficacy of AirSupra in treating or preventing bronchoconstriction and preventing asthma exacerbations?",
      "What do guidelines say about treating both symptoms and inflammation with a rescue medication?",
    ],
  },
  Tezspire: {
    leftSide: [
      "Can you provide information on the Patient Savings Program?",
      "What about use of Tezspire in men of reproductive potential?",
      "Can Tezspire be used to treat pediatric patients?",
      "Does Tezspire cause growth suppression in pediatric patients?",
    ],
    rightSide: [
      "Can Tezspire be used to treat geriatric patients/older/elderly patients?",
      "Can Tezspire be used in patients with renal or hepatic impairment?",
      "Can Tezspire be used in patients with cardiovascular conditions?",
    ],
  },
  Breztri: {
    leftSide: [
      "Can you provide information on the Patient Savings Program?",
      "What about use of Breztri in men of reproductive potential?",
      "Can Breztri be used to treat pediatric patients?",
      "Does Breztri cause growth suppression in pediatric patients?",
    ],
    rightSide: [
      "Can Breztri be used to treat geriatric patients/older/elderly patients?",
      "Can Breztri be used in patients with renal or hepatic impairment?",
      "Can Breztri be used in patients with cardiovascular conditions?",
    ],
  },
  Fasenra: {
    leftSide: [
      "Can you provide information on the Patient Savings Program?",
      "What about use of Fasenra in men of reproductive potential?",
      "Can Fasenra be used to treat pediatric patients?",
      "Does Fasenra cause growth suppression in pediatric patients?",
    ],
    rightSide: [
      "Can Fasenra be used to treat geriatric patients/older/elderly patients?",
      "Can Fasenra be used in patients with renal or hepatic impairment?",
      "Can Fasenra be used in patients with cardiovascular conditions?",
    ],
  },
  Symbicort: {
    leftSide: [
      "Can you provide information on the Patient Savings Program?",
      "What about use of Symbicort in men of reproductive potential?",
      "Can Symbicort be used to treat pediatric patients?",
      "Does Symbicort cause growth suppression in pediatric patients?",
    ],
    rightSide: [
      "Can Symbicort be used to treat geriatric patients/older/elderly patients?",
      "Can Symbicort be used in patients with renal or hepatic impairment?",
      "Can Symbicort be used in patients with cardiovascular conditions?",
    ],
  },
};
