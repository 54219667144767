import axios from "axios";

export const handleRequest = async (input, assistant = "", userId, brand) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/video/response`,
      {
        assistant: assistant,
        userInput: input,
        userId: userId,
        userBrand: brand,
      },
    );
    return { success: true, data: resp.data };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

export const checkPersonalizedQuestions = async (userId, userBrand) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/conversation/personalized`,
      {
        userId: userId,
        userBrand: userBrand,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    return { success: true, data: resp.data };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};
