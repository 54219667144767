import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Checkbox, Radio } from "antd";

const ChooseAvatar = () => {
  const [isSelected, setIsSelected] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const inviteId = urlParams.get("invite-id");

  const [value, setValue] = useState(1);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  React.useEffect(() => {
    if (inviteId === null) return;
    var actual = JSON.parse(atob(inviteId));
    setUser({
      name: actual.name,
      avatar: user.avatar,
      companyLogo: user.companyLogo,
    });
    // Access and use the inviteId parameter as needed
  }, []);

  const [user, setUser] = React.useState({
    name: "Gaurav Kandhari",
    avatar: "/images/Avatar.png",
    companyLogo: "/images/logo.png",
  });
  const handleClick = () => {
    setIsSelected(!isSelected);
  };
  const imageClick = (image) => {
    setSelectedImage(image);
  };

  const avatars = [
    {
      name: "Jonathan",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2Fjonathan2_ces_idle.png&w=256&q=75",
    },
    {
      name: "Paris",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000006173_BG00002063.png&w=256&q=75",
    },
    {
      name: "Paige",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000187655_BG00004951.png&w=256&q=75",
    },
    {
      name: "Mia",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000226456_BG00001634.png&w=256&q=75",
    },
    {
      name: "Philip",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000227753_BG00001839.png&w=256&q=75",
    },
    {
      name: "Kate",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000011954_BG00002791.png&w=256&q=75",
    },
    {
      name: "Roley",
      url: "https://sg-resource.deepbrain.io/uploads/models/en/M000022899_BG00001653.png",
    },
    {
      name: "Kerlo",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000011954_BG00003825.png&w=256&q=75",
    },
    {
      name: "Kelly",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000011954_BG00004369.png&w=256&q=75",
    },
  ];

  return (
    <div>
      <div
        className="p-6 bg-white  rounded-lg"
        style={{
          width: "69%",
          height: "61rem",
          marginLeft: "13%",
          marginTop: "2%",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{
            margin: "1rem",
            alignSelf: "flex-end",
            position: "absolute",
          }}
        >
          <Link to={`/`}>Go Back</Link>
        </Button>
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          Settings
        </h5>
        <div
          style={{ display: "flex", flexDirection: "row", alignSelf: "center" }}
        >
          <div
            style={{
              marginTop: "2rem",
              textAlign: "left",

              // height: "29rem",
              // overflow: "auto",
            }}
          >
            <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
              Change Avatar
            </p>
            <div
              className="grid grid-cols-2 md:grid-cols-3 gap-5"
              style={{ width: "51rem", textAlign: "center" }}
            >
              {avatars.map((avatar) => (
                <div
                  onClick={() => imageClick(avatar.url)}
                  style={{
                    border:
                      selectedImage === avatar.url ? "2px solid blue" : "none",
                    borderRadius: "10px",
                    background: "#80808014",
                  }}
                >
                  <img
                    style={{
                      cursor: "pointer",

                      padding: "10px",
                    }}
                    className="h-auto max-w-full rounded-lg"
                    src={avatar.url}
                    alt=""
                  />
                  <p>{avatar.name}</p>
                </div>
              ))}
            </div>
            <div style={{ marginTop: "3rem" }}>
              <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
                Change Accent
              </p>
              <div style={{ marginTop: "1rem" }}>
                <Radio.Group
                  onChange={onChange}
                  value={value}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <Radio
                    style={{ padding: "1rem", background: "#80808014" }}
                    value={1}
                  >
                    English (US)
                  </Radio>
                  <Radio
                    style={{ padding: "1rem", background: "#80808014" }}
                    value={2}
                  >
                    English (GB)
                  </Radio>
                  <Radio
                    style={{ padding: "1rem", background: "#80808014" }}
                    value={3}
                  >
                    English (AU)
                  </Radio>
                  <Radio
                    style={{ padding: "1rem", background: "#80808014" }}
                    value={4}
                  >
                    English (IN)
                  </Radio>
                </Radio.Group>
              </div>
            </div>
            <div style={{ marginTop: "3rem" }}>
              <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
                Change Mode of Chat
              </p>
              <Checkbox style={{ marginRight: "2rem" }} defaultChecked>
                Voice Chat
              </Checkbox>
              <Checkbox defaultChecked>Text Chat</Checkbox>
            </div>
            <div
              style={{
                marginTop: "2rem",
                marginBottom: "3rem",
                alignSelf: "center",
              }}
            >
              <a
                href="#khkjhk"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <Link to={`/`}>Save Changes</Link>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseAvatar;
