import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { MdOutlineSettings } from "react-icons/md";
import { BiCloset } from "react-icons/bi";
import { GiMedicines } from "react-icons/gi";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import { AiOutlineSafety } from "react-icons/ai";
import { LuLogOut } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import introJs from "intro.js";
import { MdOutlineTour } from "react-icons/md";
import { toast } from "sonner";
import Loader from "./Loader";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedinUser = useSelector((state) => state.auth.user);
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState({
    name: "",
    avatar: "/images/Avatar.png",
    companyLogo: "/images/javaablogocopy.png",
  });
  const urlParams = new URLSearchParams(window.location.search);
  const inviteId = urlParams.get("invite-id");
  const currentPage = window.location.pathname;
  const getBrandName = localStorage.getItem("brand");

  useEffect(() => {
    if (loggedinUser !== null) {
      setUser({
        name: loggedinUser.name,
        avatar: loggedinUser.avatar ? loggedinUser.avatar : user.avatar,
        companyLogo: loggedinUser.companyLogo
          ? loggedinUser.companyLogo
          : user.companyLogo,
        brand: getBrandName ? getBrandName : loggedinUser.brand,
      });
      return;
    }

    if (inviteId === null) {
      getUserInfo();
      return;
    }

    if (inviteId == null && loggedinUser === null) return getUserInfo();
  }, [loggedinUser, getBrandName]);

  async function getUserInfo() {
    try {
      setLoader(true);
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/user-info`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      dispatch({
        type: "SET_USER",
        payload: resp.data.user,
      });

      setUser({
        name: resp.data.user.name,
        avatar: resp.data.user.avatar ? resp.data.user.avatar : user.avatar,
        companyLogo: resp.data.user.companyLogo
          ? resp.data.user.companyLogo
          : user.companyLogo,
        brand: getBrandName ? getBrandName : resp.data.user.brand,
      });
    } catch (error) {
      console.log(error);
      if (currentPage !== "/signin") {
        toast.error("Something went wrong!");
        navigate("/signin");
      }
    } finally {
      setLoader(false);
    }
  }

  function getPI(brand) {
    if (brand === "lynparza") {
      return "https://www.azpicentral.com/pi.html?product=lynparza_tb";
    } else if (brand === "airsupra") {
      return "https://den8dhaj6zs0e.cloudfront.net/50fd68b9-106b-4550-b5d0-12b045f8b184/fe598cda-d255-4446-998e-617607f61552/fe598cda-d255-4446-998e-617607f61552_viewable_rendition__v.pdf";
    }
  }
  function getPIL(brand) {
    if (brand === "lynparza") {
      return "https://den8dhaj6zs0e.cloudfront.net/50fd68b9-106b-4550-b5d0-12b045f8b184/00997c3f-5912-486f-a7db-930b4639cd51/00997c3f-5912-486f-a7db-930b4639cd51_pi_med_guide_rendition__c.pdf";
    } else if (brand === "airsupra") {
      return "https://den8dhaj6zs0e.cloudfront.net/50fd68b9-106b-4550-b5d0-12b045f8b184/fe598cda-d255-4446-998e-617607f61552/fe598cda-d255-4446-998e-617607f61552_pi_med_guide_rendition__c.pdf";
    }
  }
  function getWebsiteLink(brand) {
    if (brand === "lynparza") {
      return "https://www.lynparza.com/";
    } else if (brand === "airsupra") {
      return "https://www.airsupra.com/";
    }
  }
  function getISI(brand) {
    if (brand === "lynparza") {
      return "https://docs.google.com/document/d/1Sztolx_MH1mbVPigrS8TD13AfC1cexF0jubMtX8AkGk/edit?usp=sharing";
    } else if (brand === "airsupra") {
      return "https://www.airsupra.com/safety-information";
    }
  }

  const items = [
    {
      key: "1",
      label: (
        <Link to={`/settings`} className="text-gray-700">
          <div className="flex items-center gap-x-2">
            <MdOutlineSettings className="text-gray-700 w-4 h-4" />
            Settings
          </div>
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <a
          className="text-gray-700"
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center gap-x-2">
            <BiCloset className="text-gray-700 w-4 h-4" />
            Sample Closet
          </div>
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          className="text-gray-700"
          href={getPI(user.brand)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center gap-x-2">
            <GiMedicines className="text-gray-700 w-4 h-4" />
            Prescribing Information (PI){" "}
          </div>
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          className="text-gray-700"
          target="_blank"
          rel="noopener noreferrer"
          href={getPIL(user.brand)}
        >
          <div className="flex items-center gap-x-2">
            <AiOutlineMedicineBox className="text-gray-700 w-4 h-4" />
            Patient Information Leaflet (PIL)
          </div>
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a
          className="text-gray-700"
          target="_blank"
          rel="noopener noreferrer"
          href={getWebsiteLink(user.brand)}
        >
          <div className="flex items-center gap-x-2">
            <TbWorld className="text-gray-700 w-4 h-4" /> Website link
          </div>
        </a>
      ),
    },
    {
      key: "6",
      label: (
        <a
          className="text-gray-700"
          href={getISI(user.brand)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="flex items-center gap-x-2">
            <AiOutlineSafety className="text-gray-700 w-4 h-4" />
            Important Safety Information (ISI)
          </div>
        </a>
      ),
    },
    {
      key: "7",
      label: (
        <div
          className="flex items-center gap-x-2"
          onClick={() => {
            // localStorage.removeItem("doneTour");
            introJs().start();
          }}
        >
          <MdOutlineTour className="text-gray-700" />
          Product Tour
        </div>
      ),
    },
    {
      key: "8",
      label: (
        <Link
          to={`/signin`}
          className="text-gray-700"
          onClick={() => signOut()}
        >
          <div className="flex items-center gap-x-2">
            <LuLogOut className="text-red-500 w-4 h-4" />
            Sign out
          </div>
        </Link>
      ),
    },
  ];

  const signOut = () => {
    // To remove an item from localStorage
    localStorage.removeItem("firstTime");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/signin");
    window.location.reload(true);
  };

  return (
    <div>
      {loader && (
        <div className="absolute backdrop-blur-sm w-full z-20 h-screen">
          <Loader />
        </div>
      )}

      <div className="flex justify-between items-center px-4 ">
        <img
          src="https://res.cloudinary.com/ds80j9gac/image/upload/v1709885447/jawaab_latest_ab35eb.png"
          alt="jawaab"
          className="w-auto"
          style={{
            height: "4rem",
            // position: "absolute",
            background: "white",
            // padding: "1rem",
            width: "4rem",
            zIndex: 9,
          }}
          onClick={() => navigate("/")}
        />
        <div className="flex items-center gap-2 py-3">
          <img className="h-6 w-6 rounded-full" src={user.avatar} alt="" />

          <Dropdown
            menu={{
              items,
            }}
          >
            <a
              onClick={(e) => e.preventDefault()}
              style={{ cursor: "pointer" }}
            >
              {user.name}
              <DownOutlined />
            </a>
          </Dropdown>
        </div>
      </div>
      <hr className="border-gray-300" />
    </div>
  );
};
export default Navbar;
