import React, { useContext } from "react";
import { Button, Input } from "antd";
import AvatarContext from "./AvatarContext";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import annyang from "annyang";

const AnswerNotFound = () => {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const {
    descriptionTitle,

    setConversation,
    setListening,
    playVideo,
  } = useContext(AvatarContext);

  return (
    <div
      style={{
        display: "flex",
        margin: "1rem",
        border: "1.5px solid #e2e8f0",
        borderRadius: ".8rem",
        backgroundColor: "#F9FAFB",
      }}
      className="flex-wrap items-center md:mt-[10%] mt-5"
    >
      <img
        alt="404"
        src="images/notFound.png"
        style={{
          alignSelf: "center",
        }}
        className="md:w-[45%] w-3/4 md:mx-0 mx-auto"
      />
      <div
        key={descriptionTitle}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "2rem",
        }}
        className="flex-1 animate-fade-in-1s"
      >
        <p className="text-2xl font-semibold">
          {" "}
          Sorry! Can't Find an Answer for Your Question
        </p>
        <p style={{ marginTop: "0.5rem" }} className="text-gray-500">
          Don't worry, our support team is ready to assist you. Share your
          question below.
        </p>

        <TextArea
          placeholder="question"
          rows={4}
          style={{ marginTop: "1.5rem" }}
          className="outline-none p-2 text-lg text-gray-600 w-[90%]"
          value={descriptionTitle}
        />
        <div className="flex gap-x-2 items-center mt-5">
          <Button
            // shape="round"
            style={{
              background: "#305095",
              color: "white",
            }}
            onClick={() => {
              toast.success("Question forwarded to MSL Team for assistance");
              navigate("/");
            }}
            className="rounded-full px-4 font-medium w-fit py-2 flex items-center justify-center"
          >
            Send to MSL Team
          </Button>
          <Button
            onClick={() => {
              setConversation(false);
              if (annyang) annyang.abort();
              setListening(false);
              playVideo(false);
            }}
            className="rounded-full"
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AnswerNotFound;
