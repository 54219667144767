import React, { useContext } from "react";
import { FaHistory } from "react-icons/fa";
import { useSelector } from "react-redux";
import AvatarContext from "./AvatarContext";

const PersonalizationBox = () => {
  const { personalizedQuest, setQuestion, setRequestLoader } =
    useContext(AvatarContext);
  const user = useSelector((state) => state.auth.user);
  return (
    personalizedQuest && (
      <div
        className="flex flex-col items-center justify-center  bg-indigo-100 animate-fade-in md:w-[60%] w-[90%] z-10"
        style={{
          border: "1.5px solid rgb(165 180 252)",
          borderRadius: ".8rem",
          // position: "absolute",
          // zIndex: 20,
        }}
      >
        <div className="p-4">
          <p className="font-semibold text-center">
            Hi {user?.name}, welcome back! Let’s continue on{" "}
            {personalizedQuest?.category} questions?
          </p>
          <div className="flex gap-3 flex-wrap my-2">
            {personalizedQuest?.questions.map((question, index) => (
              <div
                key={index}
                className="flex gap-2 bg-white rounded-lg p-4 align-center cursor-pointer w-[30rem] mx-auto"
                style={{ border: "1.5px solid #e2e8f0", alignItems: "center" }}
                onClick={() => {
                  setQuestion(question);
                  setRequestLoader(true);
                }}
              >
                <div>
                  <FaHistory className="h-5 w-5 text-gray-600" />
                </div>
                <div key={index} className="text-sm ">
                  {question}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default PersonalizationBox;
