import { lynparzaQuestions, airsupraQuestions } from "../components/questions";

export const content = [
  {
    type: "lynparza",
    content: [
      {
        screen: "welcome",
        video: "/videos/welcome.mp4",
      },
      {
        screen: "landing",
        video: "/videos/Jonathan-Welcome.mp4",
        question: lynparzaQuestions,
        filters: ["Disease State", "Testing", "LYNPARZA"],
      },
    ],
  },
  {
    type: "airsupra",
    content: [
      {
        screen: "welcome",
        video: "/videos/welcome-airsupra.mp4",
      },
      {
        screen: "landing",
        video: "/videos/Jonathan-Welcome-airsupra.mp4",
        question: airsupraQuestions,
        filters: ["Airsupra", "Tezspire", "Breztri", "Fasenra", "Symbicort"],
      },
    ],
  },
];
