import PrivateRoute from "./components/PrivateRoute";
import Authentication from "./pages/Authentication";
import { Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from "sonner";
import React from "react";
import Welcome from "./pages/Welcome";
import ChooseAvatar from "./pages/ChooseAvatar";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Conversation from "./pages/Conversation";
import Settings from "./pages/Settings";
import AssistentDemo from "./components/AssistentDemo";
const App = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/signup" && location.pathname !== "/signin" && (
        <Navbar />
      )}
      <Toaster position="top-right" richColors closeButton />

      <Routes>
        {/* <Route path="/signup" element={<Authentication />} /> */}
        <Route path="/signin" element={<Authentication />} />
        <Route path="/demo" element={<AssistentDemo />} />
        <Route element={<PrivateRoute />}>
          {/* <Route path="/" element={<Avatar />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/:brandName" element={<Home />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/:brand/welcome" element={<Welcome />} />

          <Route path="/choose-avatar" element={<ChooseAvatar />} />
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/conversation" element={<Conversation />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
