import React, { useContext } from "react";
import Answer from "./Answer";
import { MessageBox } from "../components";
import { Button } from "antd";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import AvatarContext from "./AvatarContext";
import AnswerNotFound from "./NotFound";
import annyang from "annyang";

const Description = () => {
  const {
    setGreetingVideo,
    setPersonalizedQuest,
    setConversation,
    showMSL,
    showFollowupQuestion,
    setListening,
    response,
    setVideoSilent,
    setAssistantQN,
  } = useContext(AvatarContext);

  return (
    <div
      className="md:w-[65%] w-full"
      style={{
        display: "flex",
        flexDirection: "column",
        // marginTop: "2rem",
      }}
    >
      {/* have answer */}
      <Button
        data-intro="Click this button to return to the home."
        data-position="left"
        type="text"
        style={{ margin: "1rem", alignSelf: "flex-end", position: "absolute" }}
        onClick={() => {
          setConversation(false);
          setGreetingVideo(null);
          setPersonalizedQuest(null);
          setAssistantQN("");
          setVideoSilent(true);

          if (annyang) annyang.abort();
          setListening(false);
          // setQuestion("");
        }}
        className={`${showMSL ? "hidden" : "block"} border border-gray-300`}
      >
        Go Back
      </Button>
      {showMSL ? <AnswerNotFound /> : <Answer />}

      {/* dont have answer */}
      {showFollowupQuestion && response.followup_question && (
        <div
          key={response.followup_question}
          data-intro="follow up question."
          style={{ alignSelf: "center" }}
          className="flex gap-2 bg-white rounded-lg my-2  md:w-[28rem] w-[85%] animate-fade-in-1s"
        >
          <AiOutlineQuestionCircle className="h-5 w-5 text-gray-600" />
          <div className="text-sm flex-1">{response.followup_question}</div>
        </div>
      )}

      <div
        className="border rounded-md bg-white mb-3"
        style={{ width: "28rem", alignSelf: "center" }}
      >
        <MessageBox />
      </div>
    </div>
  );
};

export default Description;
