import React, { useState, useContext, useRef, useEffect } from "react";
import {
  MessageBox,
  RightQuestionBox,
  LeftQuestionBox,
  PersonalizationBox,
  FilterQuestions,
} from "../components";
import AvatarContext from "../components/AvatarContext";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import annyang from "annyang";
import { fetchContent } from "../content/FetchContent";

export const Idle = () => {
  const idleRef = useRef(null);
  const greetingRef = useRef(null);

  const personalized = useSelector((state) => state.auth.personalized);
  const user = useSelector((state) => state.auth.user);
  const [question, setQuestion] = useState("");

  const [showPauseButton, setShowPauseButton] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);
  const {
    greetingVideo,
    setGreetingVideo,
    personalizedQuest,
    setRequestLoader,
    listening,
    loader,
    playing,
    setLoader,
    playVideo,
    brand,
  } = useContext(AvatarContext);
  const [greetingVideoSilent, setGreetingVideoSilent] = useState(true);
  const [storeGreetingVideo, setStoreGreetingVideo] = useState(null);
  const [selectCategoryType, setSelectCategoryType] = React.useState("");
  const [content, setContent] = useState({
    question: {},
    filters: [],
  });

  const startAnnyang = () => {
    annyang.abort();
    if (annyang) {
      // Define a command

      const commands = {
        "*text": (text) => {
          setQuestion(text);
          setRequestLoader(true);
        },
      };

      // Add commands to annyang
      annyang.addCommands(commands);

      // Start annyang
      annyang.start();
    }
  };

  useEffect(() => {
    if (user != null) {
      const { success, content } = fetchContent("landing", brand);

      if (success) setContent(content);
    }
  }, [user, brand]);

  useEffect(() => {
    if (greetingVideo) {
      if (personalized) {
        greetingRef.current.muted = false;
      }

      greetingRef.current.src = greetingVideo;
      setStoreGreetingVideo(greetingVideo);
      const checkVideoLoaded = () => {
        try {
          if (greetingRef?.current?.readyState === 4) {
            setGreetingVideoSilent(false);
            if (playing) greetingRef.current.play();
          } else {
            setTimeout(checkVideoLoaded, 100);
          }
        } catch (error) {
          throw error;
        }
      };
      checkVideoLoaded();
    } else {
      idleRef.current.src = "/videos/silence_conv.mp4";
    }
  }, [greetingVideo]);

  const togglePlay = () => {
    if (greetingRef.current) {
      greetingRef.current.play();
      playVideo(true);
    }
  };
  const togglePause = () => {
    if (greetingRef.current) {
      greetingRef.current.pause();
      playVideo(false);
    }
  };
  const playGreetingVideoAgain = () => {
    setGreetingVideo(storeGreetingVideo);
    playVideo(true);
    setShowRepeat(false);
  };
  return (
    <div
      className={`w-full relative  min-h-[95vh] ${
        personalizedQuest ? "sm:h-[110vh]" : ""
      }`}
    >
      {loader && (
        <div className="absolute backdrop-blur-sm w-full z-20 h-screen">
          <Loader />
        </div>
      )}
      <div
        className={`flex flex-col items-center justify-center pt-5 relative 
    
        md:overflow-hidden`}
        //  md:h-full
      >
        <PersonalizationBox />
        <div
          className={`flex flex-wrap w-full ${
            personalizedQuest ? "transition-width duration-700 ease-in-out" : ""
          }`}
        >
          <div className="md:w-1/3 w-3/4 md:block hidden">
            <LeftQuestionBox
              setQuestion={setQuestion}
              selectCategoryType={selectCategoryType}
              questions={content.question}
              filterOptions={content.filters}
            />
          </div>
          <div
            className={`md:w-1/3 w-[85%] flex flex-col items-center relative 
        transition-width duration-100 ease-in-out mx-auto
      `}
          >
            <div
              className="w-full px-4"
              onMouseEnter={() => setShowPauseButton(true)}
              onMouseLeave={() => setShowPauseButton(false)}
            >
              <video
                ref={greetingRef}
                // autoPlay
                className="w-full h-fit object-cover rounded-md"
                style={
                  greetingVideoSilent === false
                    ? {
                        display: "block",
                        position: "relative",
                        top: personalizedQuest ? "-6rem" : "0rem",
                      }
                    : { display: "none" }
                }
                onError={(e) => {
                  console.log("error on greeting video", e);
                }}
                onCanPlay={() => {
                  setLoader(false);
                }}
                onClick={() => (greetingRef.current.muted = false)}
                onEnded={(e) => {
                  setGreetingVideo(null);
                  if (listening) {
                    startAnnyang();
                  }
                  setTimeout(() => {
                    setGreetingVideoSilent(true);
                    setShowRepeat(true);
                  }, 50);
                }}
              />
              <video
                ref={idleRef}
                autoPlay
                muted
                onCanPlay={() => {
                  setLoader(false);
                }}
                className="w-full h-fit object-cover rounded-md animate-fade-in-1s"
                loop
                style={
                  greetingVideoSilent
                    ? {
                        display: "block",
                        position: "relative",
                        top: personalizedQuest ? "-6rem" : "0rem",
                      }
                    : { display: "none" }
                }
                onCanPlayThrough={(e) => {
                  localStorage.removeItem("firstTime");
                }}
                onError={(e) => {
                  console.log("error on idle video", e);
                }}
              />
              {showRepeat && (
                <button
                  onClick={() => playGreetingVideoAgain()}
                  style={repeatButtonStyle}
                  className="animate-fade-in-1s"
                >
                  Click to Replay
                </button>
              )}
              {!greetingVideoSilent ? (
                !playing ? (
                  <button onClick={() => togglePlay()} style={videoButtonStyle}>
                    Click to start
                  </button>
                ) : (
                  showPauseButton && (
                    <button
                      onClick={() => togglePause()}
                      style={videoButtonStyle}
                    >
                      Click to stop
                    </button>
                  )
                )
              ) : (
                <></>
              )}
            </div>
            <div
              className={`flex items-center absolute bottom-10 ${
                personalizedQuest
                  ? "md:bottom-17 transition-width duration-600 ease-in-out"
                  : "md:bottom-10"
              } sm:bottom-1/4 left-1/2 transform -translate-x-1/2   z-10`}
              style={{
                bottom: window.innerWidth >= 2560 ? "10rem" : "",
              }}
            >
              <FilterQuestions
                selectCategoryType={selectCategoryType}
                setSelectCategoryType={setSelectCategoryType}
                filterOptions={content?.filters}
              />
              <div className="bg-white border rounded-md">
                <MessageBox question={question} setQuestion={setQuestion} />
              </div>
            </div>
          </div>
          <div className="md:w-1/3 w-full  md:hidden block mx-auto">
            {/* <LeftQuestionBox setQuestion={setQuestion} /> */}
          </div>
          <div className="md:w-1/3 w-full mx-auto">
            <RightQuestionBox
              setQuestion={setQuestion}
              selectCategoryType={selectCategoryType}
              questions={content.question}
              filterOptions={content.filters}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const repeatButtonStyle = {
  position: "absolute",

  top: "60%",
  left: "40%",
  padding: "5px 10px",
  cursor: "pointer",
  border: "none",
  background: "rgba(255, 255, 255, 0.7)",
  borderRadius: "5px",
  fontSize: "small",
};

const videoButtonStyle = {
  position: "absolute",

  top: "60%",
  left: "40%",
  padding: "5px 10px",
  cursor: "pointer",
  border: "none",
  background: "rgba(255, 255, 255, 0.7)",
  borderRadius: "5px",
  fontSize: "small",
};

export default Idle;
