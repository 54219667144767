import { combineReducers } from "redux";

import product from "./product";
import list from "./list";
import auth from "./auth";

export default combineReducers({
  auth: auth,
  list: list,
  product: product,
});
