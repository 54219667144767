import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { isExpired } from "react-jwt";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const inviteId = urlParams.get("invite-id");

  if (inviteId == null) {
    let auth = localStorage.getItem("accessToken");
    // if (!auth) navigate("/signin");

    const isMyTokenExpired = isExpired(auth);
    // if (isMyTokenExpired) localStorage.clear();

    // return !isMyTokenExpired ? <Outlet /> : <Navigate to="/signin" />;
    return <Outlet />;
  } else {
    return <Outlet />;
  }
};
export default PrivateRoutes;
