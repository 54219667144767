const initState = {
  loading: false,
  user: null,
  personalized: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "CHANGE_LOADING_STATE":
      return {
        ...state,
        loading: !state.loading,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "CHANGE_COMPANYURL":
      return {
        ...state,
        user: {
          ...state.user,
          companyURL: action.payload,
        },
      };
    case "SET_PERSONALIZED_VIDEO":
      return {
        ...state,
        personalized: action.payload,
      };
    default:
      return state;
  }
};
