import React from "react";
import Video from "../components/Video";
import Description from "../components/Description";

const Conversation = () => {
  localStorage.removeItem("firstTime");
  const navbarHeight = 2.8;
  const remToPixels = 16;
  const scrollAmount = navbarHeight * remToPixels;
  window.scrollTo(0, scrollAmount);
  return (
    <div
      className={`flex justify-between transition-opacity ease-in-out h-[calc(100vh-60px)] mx-5 flex-wrap`}
    >
      <Video />

      <Description />
    </div>
  );
};

export default Conversation;
