import { content } from "./StaticContent.js";

export function fetchContent(screen, brand = "lynparza") {
  try {
    const each = content.find((each) => each.type === brand);
    if (each) {
      const content = each.content.find((content) => content.screen === screen);
      if (content) {
        return { success: true, content: content };
      } else {
        return { success: false, content: "No content found" };
      }
    } else {
      return { success: false, content: "No content found" };
    }
  } catch (error) {
    console.log(error);
    return { success: false, content: "No content found" };
  }
}
