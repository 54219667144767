import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Radio } from "antd";
import { FaArrowRight } from "react-icons/fa6";
import Loader from "../components/Loader";

const ChooseAvatar = () => {
  const [isSelected, setIsSelected] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const inviteId = urlParams.get("invite-id");
  localStorage.setItem("voiceMode", JSON.stringify(true));
  localStorage.setItem("firstTime", JSON.stringify(true));
  const [value, setValue] = useState(1);
  const [loader, setLoader] = useState(true);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const setVoiceMode = (checked) => {
    localStorage.setItem("voiceMode", JSON.stringify(checked));
  };
  React.useEffect(() => {
    if (inviteId === null) return;
    var actual = JSON.parse(atob(inviteId));
    setUser({
      name: actual.name,
      avatar: user.avatar,
      companyLogo: user.companyLogo,
    });
  }, []);

  const videoRef = useRef(null);
  const [user, setUser] = React.useState({
    name: "Lince Mathew",
    avatar: "/images/Avatar.png",
    companyLogo: "/images/logo.png",
  });
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const handleCardClick = (name) => {
    setSelectedAvatar(name);
  };
  const avatars = [
    {
      name: "Jonathan",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2Fjonathan2_ces_idle.png&w=256&q=75",
    },
    {
      name: "Paris",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000006173_BG00002063.png&w=256&q=75",
    },
    {
      name: "Paige",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000187655_BG00004951.png&w=256&q=75",
    },
    {
      name: "Mia",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000226456_BG00001634.png&w=256&q=75",
    },
    {
      name: "Philip",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000227753_BG00001839.png&w=256&q=75",
    },
    {
      name: "Dom",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000021306_BG00001491.png&w=256&q=75",
    },
    {
      name: "Roley",
      url: "https://sg-resource.deepbrain.io/uploads/models/en/M000022899_BG00001653.png",
    },
    {
      name: "Lucy",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000228473_BG00002339.png&w=256&q=75",
    },
    {
      name: "Kelly",
      url: "https://v3.aistudios.com/_next/image?url=https%3A%2F%2Fsg-resource.deepbrain.io%2Fuploads%2Fmodels%2Fen%2FM000005031_BG00003558.png&w=256&q=75",
    },
  ];

  return (
    <div
      className="animate-fade-in-1s"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)", // accounting for the navbar height
      }}
    >
      {loader && (
        <div className="absolute backdrop-blur-sm w-full z-20 h-screen">
          <Loader />
        </div>
      )}
      {/* Heading */}

      <h5 className="mb-2 mt-4 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        Personalize your Avatar
      </h5>
      {/* Main container */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          overflow: "hidden",
          marginTop: "2rem",
        }}
      >
        {/* Left side - Video container */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
            maxWidth: "300px",
          }}
        >
          <video
            style={{ width: "100%" }}
            src="/videos/chooseavatar.mp4"
            ref={videoRef}
            onCanPlay={() => setLoader(false)}
            autoPlay
            // loop
            muted={false}
            playsinline="true"
            onError={(e) => {
              console.log("error", e);
            }}
            onClick={() => videoRef.current.play()}
          />
        </div>

        {/* Right side - Content container */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
            height: "100%",
            overflowY: "auto",
          }}
        >
          {/* New heading above the grid */}
          <p
            className="font-normal text-gray-600 dark:text-gray-400"
            style={{ alignSelf: "flex-start", padding: "20px 20px 0 20px" }}
          >
            Select an avatar for your personal AI assistant
          </p>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "30px",
              padding: "20px",
              width: "100%",
            }}
          >
            {avatars.map((avatar, index) => (
              <div
                key={index}
                onClick={() => handleCardClick(avatar.name)}
                style={{
                  cursor: "pointer",
                  border:
                    selectedAvatar === avatar.name
                      ? "2px solid blue"
                      : "1px solid #dbd3d3",
                  borderRadius: "10px",
                  background: "#80808014",
                  padding: "10px",
                  boxSizing: "border-box",
                  width: "calc(33.333% - 20px)", // Subtracting the gap from the width
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src={avatar.url}
                  alt={avatar.name}
                  style={{
                    width: "100%",
                    height: "auto",
                    marginBottom: "10px",
                  }}
                />
                <span>{avatar.name}</span>
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: "3rem",
              padding: "20px 20px 0 20px",
              alignSelf: "flex-start",
            }}
          >
            <p className="font-normal text-gray-600 dark:text-gray-400">
              Select an accent for your personal AI assistant
            </p>
            <div style={{ marginTop: "1rem" }}>
              <Radio.Group
                onChange={onChange}
                value={value}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Radio
                  style={{
                    padding: "1rem",
                    background: "#80808014",
                    borderRadius: "10px",
                    border: "1px solid #dbd3d3",
                  }}
                  value={1}
                >
                  English (US)
                </Radio>
                <Radio
                  style={{
                    padding: "1rem",
                    background: "#80808014",
                    borderRadius: "10px",
                    border: "1px solid #dbd3d3",
                  }}
                  value={2}
                >
                  English (GB)
                </Radio>
                <Radio
                  style={{
                    padding: "1rem",
                    background: "#80808014",
                    borderRadius: "10px",
                    border: "1px solid #dbd3d3",
                  }}
                  value={3}
                >
                  English (AU)
                </Radio>
                <Radio
                  style={{
                    padding: "1rem",
                    background: "#80808014",
                    borderRadius: "10px",
                    border: "1px solid #dbd3d3",
                  }}
                  value={4}
                >
                  English (IN)
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div
            style={{
              marginTop: "3rem",
              alignSelf: "flex-start",
              padding: "20px 20px 0 20px",
            }}
          >
            <p className="font-normal text-gray-600 dark:text-gray-400">
              Select Mode of Chat
            </p>
            <Checkbox
              defaultChecked
              style={{ marginRight: "2rem", marginTop: "1rem" }}
              onChange={(e) => setVoiceMode(e.target.checked)}
            >
              Voice Chat
            </Checkbox>
            <Checkbox checked>Text Chat</Checkbox>
          </div>
          <div
            style={{
              marginTop: "2rem",
              alignSelf: "flex-start",
              padding: "20px",
            }}
          >
            <Link
              className="inline-flex items-center"
              // to={`/?invite-id=${inviteId}`}
              to="/"
              style={{
                width: "100%",
                justifyContent: "center",

                marginBottom: "20px",
                alignSelf: "flex-start",
              }}
            >
              <button style={nextButtonStyle}>
                Get Started <FaArrowRight />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const nextButtonStyle = {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  background: "#305095",
  color: "white",
  fontWeight: "bold",
  padding: "0.5rem 1rem",
  borderRadius: "0.5rem",
  justifyContent: "center",
  textAlign: "center",
};

export default ChooseAvatar;
